import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import NotFound from './pages/404';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Repositories from './pages/Repositories';
import How from './pages/HowIDoIt';

import { ContentWrapper } from './components/common/ContentWrapper';
import { Nav } from './components/common/Nav';
import { Wrapper } from './components/common/Wrapper';

import { GlobalStyles } from './styles/global';
import { dark } from './styles/themes/dark';

function App() {
  return (
    // browser Route defautl > title < default tittletemplate = %
    <BrowserRouter>
      <Helmet defaultTitle="Luiz Eduardo • Eduardo" titleTemplate="%s • Eduardo" />
      <ThemeProvider theme={dark}>
        <GlobalStyles />
        <Wrapper>
          <Nav />
          <ContentWrapper>
            <Routes>
              <Route index element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/how" element={<How />} />
              <Route path="/repository" element={<Repositories />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ContentWrapper>
        </Wrapper>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
