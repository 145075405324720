import Tooltip from '@tippyjs/react';
import { useState, useMemo } from 'react';
import { useBirthdayCountdown } from '../components/common/Hooks/useBirthdayCountdown';
import { Wrapper } from './Wrapper';

import { Paragraph } from '../components/common/Paragraph';
import { SmallTitle } from '../components/common/SmallTitle';
import { Title } from '../components/common/Title';
import { ExternalLink } from '../components/icons/ExternalLink';

export default function Home() {
  const { formattedAgeCountdown, myAge } = useBirthdayCountdown();

  return (
    <Wrapper forceMaxWidth>
      <Title>Hi there 🍃</Title>

      <Paragraph>
        Hello, I&#39;m Luiz, commonly referred to as &apos;Sweezy&apos; among my social circles and
        online.
      </Paragraph>

      <Paragraph>
        At{' '}
        <Tooltip content={formattedAgeCountdown} theme="custom-tooltip" arrow>
          <strong>{myAge}</strong>
        </Tooltip>{' '}
        years old, I&#39;m deeply passionate about technology, continuously striving to broaden my
        expertise by exploring emerging trends and state-of-the-art innovations. Fueled by an
        insatiable curiosity, I am always eager to seize learning opportunities and remain at the
        forefront of the ever-evolving tech landscape.
      </Paragraph>

      <Paragraph>
        During my leisure hours, I enjoy indulging in music from my curated{' '}
        <a
          href="https://open.spotify.com/intl-pt/artist/78nr1pVnDR7qZH6QbVMYZf?si=_KFnvx6FRiiMLqerbRJaZw"
          target="_blank"
          rel="noreferrer"
        >
          playlist <ExternalLink />
        </a>{' '}
        and engaging in gaming sessions, including titles like Valorant, League of Legends, Free
        Fire, Elsword, among others.
      </Paragraph>

      <SmallTitle>How I started programming</SmallTitle>

      <Paragraph>
        I ignited my programming passion in early 2019, initially immersing myself in C# as my
        programming language of choice. However, in 2020, I faced a setback when I contracted the
        COVID-19 virus, necessitating a pause in my studies and programming endeavors. In 2022,
        determined to reignite my enthusiasm for development, I resumed from where I left off.
      </Paragraph>

      <Paragraph>
        In mid-2022, I embarked on my journey with JavaScript using the Node.js engine. Initially, I
        crafted Discord bots, and later that year, towards the end of 2022, I ventured into web
        development using React.
      </Paragraph>

      <SmallTitle>Where did I learn everything?</SmallTitle>
      <Paragraph>
        My journey into programming started with a combination of self-learning and participation in
        renowned online courses. Exploring online resources such as Rocketseat Stack Overflow, among
        others, I gained a solid understanding of programming fundamentals, programming languages,
        and advanced concepts.
      </Paragraph>

      <Paragraph>
        Engaging in online communities, technology events, and hackathons has also played a
        significant role in my learning journey. Exchanging experiences with fellow enthusiasts and
        industry professionals has been a valuable source of inspiration and continuous learning.
      </Paragraph>

      <SmallTitle>Why this nickname?</SmallTitle>

      <Paragraph>
        Upon creating my initial Discord account, I deliberated over the selection of an appropriate
        username. Initially considering Ezzy, I later determined that Sweezy held greater resonance
        and potential. This chosen alias has since become deeply intertwined with my online persona,
        shaping my digital identity and fostering a sense of familiarity among my peers and
        associates within the online community.
      </Paragraph>
    </Wrapper>
  );
}
