import { Helmet } from 'react-helmet';
import { Wrapper } from './Wrapper';
import { Paragraph } from '../components/common/Paragraph';
import { SmallTitle } from '../components/common/SmallTitle';
import { Title } from '../components/common/Title';
import { ExternalLink } from '../components/icons/ExternalLink';

export default function Contact() {
  return (
    <Wrapper forceMaxWidth>
      <Helmet>
        <title>Contact</title>
      </Helmet>

      <Title>Thanks</Title>
      <Paragraph>
        Thanks for the visit. I hope you enjoyed your stay. The source code for this site will be
        available as soon as the project is completed.
      </Paragraph>

      <Title>Contact me</Title>
      <Paragraph>
        I&#39;m most responsive through discord DMs, you can{' '}
        <a
          href="https://discord.com/users/852580927665209376"
          target="_blank"
          rel="noreferrer"
          style={{ color: 'pink' }}
        >
          click here
        </a>{' '}
        to DM me on Discord.
      </Paragraph>
      <Paragraph>
        If your question is related to a network that I manage or control (e.g. AS952 or AS399531),
        please send an email to my email listed in the respective WHOIS contact. If it is an
        emergency, you can email{' '}
        <a href="mailto:eduardodev10@gmail.com" style={{ color: '#ffc0cb' }}>
          eduardodev10@gmail.com
        </a>
        .
      </Paragraph>

      <img
        src="/static/images/cat-kawaii.gif"
        alt="computer"
        style={{
          width: '240px',
          maxWidth: '1000px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          top: '30px',
          left: '25px',
        }}
      />
    </Wrapper>
  );
}
