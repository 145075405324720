import { useState, useEffect, useMemo } from 'react';
import { BIRTH_DATE } from '../../../utils/constants';

const YEAR_MS = 31556926000;

export function useBirthdayCountdown() {
  const [birthDayCountdown, setBirthDayCountdown] = useState(() => {
    const birthTime = BIRTH_DATE.getTime() % YEAR_MS;
    const nowTime = Date.now() % YEAR_MS;

    return nowTime > birthTime ? birthTime + YEAR_MS - nowTime : birthTime - nowTime;
  });

  const myAge = Math.floor((Date.now() - BIRTH_DATE.getTime()) / YEAR_MS);

  const formattedAgeCountdown = useMemo(() => {
    if (YEAR_MS - birthDayCountdown <= 86400000) {
      return 'Happy Birthday Sweezy! 🎉';
    }

    let seconds = Math.floor(birthDayCountdown / 1000);

    const days = Math.floor(seconds / 86400);
    seconds %= 86400;

    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;

    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }, [birthDayCountdown]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBirthDayCountdown((state) => (state - 1000 <= 0 ? YEAR_MS : state - 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return { formattedAgeCountdown, myAge };
}
